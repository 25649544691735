module.exports = [{
      plugin: require('/Users/mgechev/Projects/guess-js.github.io/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mgechev/Projects/guess-js.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mgechev/Projects/guess-js.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-128381568-1"},
    },{
      plugin: require('/Users/mgechev/Projects/guess-js.github.io/node_modules/gatsby-plugin-guess-js/gatsby-browser'),
      options: {"plugins":[],"GAViewID":"184199008","minimumThreshold":0.03,"period":{"startDate":"2019-09-06T21:29:48.029Z","endDate":"2019-09-13T21:29:48.029Z"}},
    },{
      plugin: require('/Users/mgechev/Projects/guess-js.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
